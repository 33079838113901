.title-text{
    font-family: "Arimo", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.text{
   font-size: 18px;
   font-family: "Nunito Sans", sans-serif;
   font-optical-sizing: auto;
   font-style: normal;
   font-weight: 500;
}

.text-two{
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
 }


 
 