
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .loading-screen {
    animation: fadeIn 1s forwards; /* Fade in effect */
  }
  

.block-two-one{
    display:block
}
.block-two-two{
    display:none
}

.block-one-one{
    display:block
}
.block-one-two{
    display:none
}

.font-header{
    font-family: "Domine", serif;
    font-optical-sizing: auto;
}
.font-header-main{
    font-family: "Noto Serif Oriya", serif;
    font-optical-sizing: auto;
}

.padding-left {
    padding-left: 18px;
}

@media all and (max-width: 1250px) {
    .block-two-one{
        display:none
    }
    .block-two-two{
        display:block
    }

    @media all and (max-width: 870px) {
        .block-one-one{
            display:none
        }
        .block-one-two{
            display:block
        }
    }

    @media (max-width: 640px) {
        .padding-left {
          padding-left: 0;
        }
      }
}